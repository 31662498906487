import { colors, hexToRGBA } from './customColors';

type Style = {
  background: string;
  text: string;
};

type DisabledStyle = {
  background: string;
  text: string;
};

export function disable(
  style: Style,
  textOpacity: number = 0.26,
  backgroundOpacity: number = 0.26,
): DisabledStyle {
  return {
    background: hexToRGBA(style.background, backgroundOpacity),
    text: hexToRGBA(style.text, textOpacity),
  };
}

export const turquoiseAndLightGrey = {
  background: colors.turquoise,
  text: colors.lightGrey,
};

export const darkTurquoiseAndLightGrey = {
  background: colors.darkTurquoise,
  text: colors.lightGrey,
};

export const darkBlueAndTurquoise = {
  background: colors.darkBlue,
  text: colors.lightGrey,
};
