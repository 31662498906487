import React, { useCallback } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

// Inactive SVGs
import { ReactComponent as UcmLogoIcon } from 'src/images/UcmLogo.svg';
import { ReactComponent as FineIcon } from 'src/images/DrawerIcons/Fine.svg';
import { ReactComponent as HomeIcon } from 'src/images/DrawerIcons/Home.svg';
import { ReactComponent as GatekeeperIcon } from 'src/images/DrawerIcons/Door.svg';
import { ReactComponent as SetupIcon } from 'src/images/DrawerIcons/Setup.svg';
import { ReactComponent as LogoutIcon } from 'src/images/DrawerIcons/Logout.svg';
import { ReactComponent as SearchIcon } from 'src/images/DrawerIcons/Search.svg';
import { ReactComponent as ProjectIcon } from 'src/images/DrawerIcons/Project.svg';
import { ReactComponent as StudentIcon } from 'src/images/DrawerIcons/Student.svg';
import { ReactComponent as ContractIcon } from 'src/images/DrawerIcons/Contract.svg';
import { ReactComponent as ConditionIcon } from 'src/images/DrawerIcons/Condition.svg';
import { ReactComponent as TimeTrackingIcon } from 'src/images/DrawerIcons/TimeTracking.svg';
import { ReactComponent as CancellationIcon } from 'src/images/DrawerIcons/Cancellation.svg';
import { ReactComponent as VerificationIcon } from 'src/images/DrawerIcons/Verification.svg';

// Active SVGs
import { ReactComponent as ActiveFineIcon } from 'src/images/DrawerIcons/ActiveFine.svg';
import { ReactComponent as ActiveHomeIcon } from 'src/images/DrawerIcons/ActiveHome.svg';
import { ReactComponent as ActiveSetupIcon } from 'src/images/DrawerIcons/ActiveSetup.svg';
import { ReactComponent as ActiveSearchIcon } from 'src/images/DrawerIcons/ActiveSearch.svg';
import { ReactComponent as ActiveProjectIcon } from 'src/images/DrawerIcons/ActiveProject.svg';
import { ReactComponent as ActiveStudentIcon } from 'src/images/DrawerIcons/ActiveStudent.svg';
import { ReactComponent as ActiveGatekeeperIcon } from 'src/images/DrawerIcons/ActiveDoor.svg';
import { ReactComponent as ActiveContractIcon } from 'src/images/DrawerIcons/ActiveContract.svg';
import { ReactComponent as ActiveConditionIcon } from 'src/images/DrawerIcons/ActiveCondition.svg';
import { ReactComponent as ActiveTimeTrackingIcon } from 'src/images/DrawerIcons/ActiveTimeTracking.svg';
import { ReactComponent as ActiveCancellationIcon } from 'src/images/DrawerIcons/ActiveCancellation.svg';
import { ReactComponent as ActiveVerificationIcon } from 'src/images/DrawerIcons/ActiveVerification.svg';

import useMessenger from 'src/utils/hooks/useMessenger';
import { removeAccessToken } from 'src/utils/hooks/useAuthentication';
import NavTabs, { type NavTabProps } from 'src/components/NavTabs';

const DRAWER_WIDTH = 70;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    paddingLeft: DRAWER_WIDTH,
  },
  appBarContent: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarIcon: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: DRAWER_WIDTH,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  drawerHeader: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'block',
  },
  listItemButton: {
    justifyContent: 'center',
  },
  listItemIcon: {
    justifyContent: 'center',
  },
  logoutButton: {
    minHeight: 48,
    justifyContent: 'center',
  },
  logoutIcon: {
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: DRAWER_WIDTH,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(12),
    // height: `calc(100vh - ${theme.spacing(12)} - ${theme.spacing(3)})`
  },
}));

interface MenuItemType {
  url: string;
  text: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  activeIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export const menuItems: Array<MenuItemType> = [
  {
    text: 'Home',
    icon: HomeIcon,
    activeIcon: ActiveHomeIcon,
    url: '/home',
  },
  {
    text: 'Search',
    icon: SearchIcon,
    activeIcon: ActiveSearchIcon,
    url: 'disabled',
  },
  {
    text: 'Projects',
    icon: ProjectIcon,
    activeIcon: ActiveProjectIcon,
    url: 'disabled',
  },
  {
    text: 'Cancellations',
    icon: CancellationIcon,
    activeIcon: ActiveCancellationIcon,
    url: 'disabled',
  },
  {
    text: 'Students',
    icon: StudentIcon,
    activeIcon: ActiveStudentIcon,
    url: 'disabled',
  },
  {
    text: 'Verification',
    icon: VerificationIcon,
    activeIcon: ActiveVerificationIcon,
    url: 'disabled',
  },
  {
    text: 'Contracts',
    icon: ContractIcon,
    activeIcon: ActiveContractIcon,
    url: 'disabled',
  },
  {
    text: 'Time Tracking',
    icon: TimeTrackingIcon,
    activeIcon: ActiveTimeTrackingIcon,
    url: 'disabled',
  },
  {
    text: 'Fines',
    icon: FineIcon,
    activeIcon: ActiveFineIcon,
    url: 'disabled',
  },
  {
    text: 'Conditions',
    icon: ConditionIcon,
    activeIcon: ActiveConditionIcon,
    url: 'disabled',
  },
  {
    text: 'Gatekeeper',
    icon: GatekeeperIcon,
    activeIcon: ActiveGatekeeperIcon,
    url: '/gatekeeper',
  },
  {
    text: 'Setup',
    icon: SetupIcon,
    activeIcon: ActiveSetupIcon,
    url: 'disabled',
  },
];

type PageTitleType =
  | 'Home'
  | 'Search'
  | 'Projects'
  | 'Cancellations'
  | 'Students'
  | 'Verification'
  | 'Contracts'
  | 'Time Tracking'
  | 'Fines'
  | 'Conditions'
  | 'Gatekeeper'
  | 'Setup';

interface GenericPageProps extends NavTabProps {
  title: PageTitleType;
  children: React.ReactNode;
}

export default function PageLayout({
  title,
  children,
  ...navProps
}: GenericPageProps) {
  const classes = useStyles();
  const navigate = useNavigate();
  const messenger = useMessenger();

  const handleMenuItemClick = useCallback(
    ({ url, text }: { url: string; text?: string }) => {
      url === 'disabled'
        ? messenger.info(
          <div>
            Oops! The "{text}" feature is still under construction. <br />
            We're working hard to bring it to you soon. Thanks for your
            patience!
          </div>,
        )
        : navigate(url);
    },
    [],
  );

  const getTitleIcon = useCallback(() => {
    const currentItem = menuItems.find((item) => item.text === title);
    return currentItem ? currentItem.activeIcon : null;
  }, [title]);

  const ActiveIcon = getTitleIcon();

  return (
    <Box className={classes.root}>
      <MuiAppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box className={classes.appBarContent}>
            <Grid container alignItems="center" spacing={2}>
              <Grid>{ActiveIcon != null && <ActiveIcon />}</Grid>

              <Grid>
                <Typography variant="h4" noWrap>
                  {title}
                </Typography>
              </Grid>

              <Grid>
                <NavTabs {...navProps} />
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </MuiAppBar>

      <MuiDrawer variant="permanent" className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <UcmLogoIcon height={40} />
        </div>

        <List>
          {menuItems.map(
            ({ text, icon: Icon, activeIcon: ActiveIcon, url }) => (
              <ListItem key={text} disablePadding className={classes.listItem}>
                <Tooltip title={text} placement="right">
                  <ListItemButton
                    className={classes.listItemButton}
                    onClick={() => handleMenuItemClick({ url, text })}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      {location.pathname === url && url !== 'disabled' ? (
                        <ActiveIcon />
                      ) : (
                        <Icon />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ),
          )}
        </List>

        <Box sx={{ flexGrow: 1 }} />
        <List>
          <ListItem disablePadding className={classes.listItem}>
            <Tooltip title="Logout" placement="right">
              <ListItemButton
                className={classes.logoutButton}
                onClick={() => {
                  removeAccessToken();
                  handleMenuItemClick({ url: '/login' });
                }}
              >
                <ListItemIcon className={classes.logoutIcon}>
                  <LogoutIcon />
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>
      </MuiDrawer>

      <Box className={classes.content}>{children}</Box>
    </Box>
  );
}
