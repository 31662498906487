import { Routes, Route, Navigate } from 'react-router-dom';

import { setLocale } from 'yup';
import { useIntl } from 'react-intl';

import messages from 'src/messages';
import HomePage from 'src/pages/HomePage';
import LoginPage from 'src/pages/Auth/LoginPage';
import GatekeeperPage from 'src/pages/GatekeeperPage';
import { ProtectedRoute } from 'src/utils/hooks/useAuthentication';

const Router = () => {
  const { formatMessage } = useIntl();

  setLocale({
    mixed: {
      required: formatMessage(messages.requiredField),
      notType: (params: any) => {
        const args = { type: params.type };
        if (params.path.toLowerCase().includes('time')) args.type = 'time';
        return formatMessage(messages.notTypeField, args);
      },
    },
    number: {
      integer: formatMessage(messages.integerField),
      positive: formatMessage(messages.positiveField),
      min: (params: any) => formatMessage(messages.minValueField, params),
      max: (params: any) => formatMessage(messages.maxValueField, params),
    },
    date: {
      min: ({ min }: any) =>
        formatMessage(messages.minDateField, { min: min.format('L') }),
      max: ({ max }: any) =>
        formatMessage(messages.maxDateField, { max: max.format('L') }),
    },
  });

  return (
    <Routes>
      {/* Public route */}
      <Route path="/login" element={<LoginPage />} />

      {/* Protected routes */}
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/gatekeeper" element={<GatekeeperPage />} />
      </Route>
    </Routes>
  );
};

export default Router;
