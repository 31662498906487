import Stack from '@mui/material/Stack';
import styled from 'styled-components';
import { colors } from 'src/utils/customColors';

export const Styled = {
  ToggleButton: styled(Stack)({
    color: colors.darkerGrey,
    cursor: 'pointer',
    width: 'fit-content',
    minHeight: '56px',
    alignItems: 'center',
  }),
};
