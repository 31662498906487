import { API } from 'src/reactQuery/Api';
import { useNavigate } from 'react-router-dom';
import { getRequest, serialize, setupAxios } from 'src/utils/request';
import { useMutation } from '@tanstack/react-query';
import { setAccessToken } from 'src/utils/hooks/useAuthentication';
import { RequestMinimalData } from 'src/types/requestData';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { CodeResponse } from '@react-oauth/google';

const loginGoogle = async (
  payload: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>,
) => {
  const { data } = await getRequest<RequestMinimalData<UCM.LoginResponseType>>(
    `${API.GET.loginGoogle}?${serialize(payload)}`,
    {
      headers: {
        Accept: 'application/vnd.falcon.v1+json',
      },
    },
  );

  return data;
};

export const useGoogleLoginMutation = () => {
  const { handleError, handleSuccess } = useMessageHandler();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: loginGoogle,
    onSuccess: ({ data: { attributes } }) => {
      navigate('/home');
      setAccessToken(attributes.token);
      setupAxios(attributes.token);
      handleSuccess('User login successful', 1000);
    },
    onError: handleError,
  });
};
