/*
 * Gatekeeper Dashboard  Messages
 *
 * This contains all the text for the Evaluation Modal component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.GatekeeperPage';

export default defineMessages({
  editPreregHint: {
    id: `${scope}.editPreregHint`,
    defaultMessage: 'Edit Pre-reg',
  },
  createPrescreenHint: {
    id: `${scope}.createPrescreenHint`,
    defaultMessage: 'Create Pre-screen',
  },
  editPrescreenHint: {
    id: `${scope}.editPrescreenHint`,
    defaultMessage: 'Edit Pre-screen',
  },
  forceAccessHint: {
    id: `${scope}.forceAccessHint`,
    defaultMessage: 'Force Access',
  },
  videoUrlHint: {
    id: `${scope}.videoUrlHint`,
    defaultMessage: 'Hireflix video',
  },
  emailColumnLabel: {
    id: `${scope}.emailColumnLabel`,
    defaultMessage: 'Email',
  },
  phoneColumnLabel: {
    id: `${scope}.phoneColumnLabel`,
    defaultMessage: 'Phone Number',
  },
  accessPausedColumnLabel: {
    id: `${scope}.accessPausedColumnLabel`,
    defaultMessage: 'Paused Since',
  },
  accessReasonsLabel: {
    id: `${scope}.accessReasonsLabel`,
    defaultMessage: 'Pause Reason',
  },
  leadSinceColumnLabel: {
    id: `${scope}.leadSinceColumnLabel`,
    defaultMessage: 'Lead Since',
  },
  campaignColumnLabel: {
    id: `${scope}.campaignColumnLabel`,
    defaultMessage: 'Marketing Campaign / Project',
  },
  statusColumnLabel: {
    id: `${scope}.statusColumnLabel`,
    defaultMessage: 'Pre-screening status',
  },
  accountSinceColumnLabel: {
    id: `${scope}.accountSinceColumnLabel`,
    defaultMessage: 'Account Since',
  },
  evaluatedSinceColumnLabel: {
    id: `${scope}.evaluatedSinceColumnLabel`,
    defaultMessage: 'Evaluated Since',
  },
  baseProfileSinceColumnLabel: {
    id: `${scope}.baseProfileSinceColumnLabel`,
    defaultMessage: 'Base profile Since',
  },
  interviewCompletedColumnLabel: {
    id: `${scope}.interviewCompletedColumnLabel`,
    defaultMessage: 'Interview completed since',
  },
  userProfileColumnLabel: {
    id: `${scope}.userProfileColumnLabel`,
    defaultMessage: 'User potential',
  },
  videoColumnLabel: {
    id: `${scope}.videoColumnLabel`,
    defaultMessage: 'Video',
  },
  evaluateColumnLabel: {
    id: `${scope}.evaluateColumnLabel`,
    defaultMessage: 'Evaluate',
  },
  evaluatedByColumnLabel: {
    id: `${scope}.evaluatedByColumnLabel`,
    defaultMessage: 'Evaluated by',
  },
  reasonColumnLabel: {
    id: `${scope}.reasonColumnLabel`,
    defaultMessage: 'Waitlist reason',
  },
  searchInputPlaceholder: {
    id: `${scope}.searchInputPlaceholder`,
    defaultMessage: 'Name, Email',
  },
  accessGrantedSuccess: {
    id: `${scope}.accessGrantedSuccess`,
    defaultMessage: 'Access granted to the user',
  },
  accessGrantedConfirmMessage: {
    id: `${scope}.accessGrantedConfirmMessage`,
    defaultMessage:
      'Do you really want to force access for this user? The User will get full access to ucm.jobs!',
  },
  stageColumnLabel: {
    id: `${scope}.stageColumnLabel`,
    defaultMessage: 'Stage',
  },
  stageTimeColumnLabel: {
    id: `${scope}.stageTimeColumnLabel`,
    defaultMessage: 'Stage time',
  },
  firstNameColumnLabel: {
    id: `${scope}.firstNameColumnLabel`,
    defaultMessage: 'First name',
  },
  lastNameColumnLabel: {
    id: `${scope}.lastNameColumnLabel`,
    defaultMessage: 'Last name',
  },
});
