import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useGoogleLogin } from '@react-oauth/google';

// Import SVGs as React components
import { ReactComponent as UcmLogoIcon } from 'src/images/UcmLogo.svg';

import {
  useLoginUserMutation,
  useGoogleLoginMutation,
} from 'src/pages/Auth/api';
import { env, isProd } from 'src/config';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { colors } from 'src/utils/customColors';
import Container from '@mui/material/Container';
import TextInput from 'src/components/TextInput';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import { removeAccessToken } from 'src/utils/hooks/useAuthentication';

import messages from './messages';
import { lowerCase, startCase } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.turquoise,
  },
  formContainer: {
    width: 500,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5),
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
  },
  submit: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '&:first-of-type': {
      marginTop: theme.spacing(3),
    },
  },
  textInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: theme.spacing(7),
    },
  },
}));

export default function LoginPage() {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { mutate: loginGoogleUser, isPending: isPendingGoogleLogin } =
    useGoogleLoginMutation();
  const { mutate: loginUser, isPending: isPendingLogin } =
    useLoginUserMutation();

  const loginGoogle = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: loginGoogleUser,
  });

  useEffect(() => {
    removeAccessToken();
  }, []);

  const handleLogin = useCallback(() => {
    loginUser({ email, password });
  }, [email, password]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      if (name === 'email') {
        setEmail(value);
      } else if (name === 'password') {
        setPassword(value);
      }
    },
    [],
  );

  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <CssBaseline />
      <Paper className={classes.formContainer} elevation={6}>
        <UcmLogoIcon height={80} />

        <Typography variant="h4" textAlign={'center'} mb={1}>
          {formatMessage(messages.titleProd)}
        </Typography>
        <Typography variant="h5" textAlign={'center'} color="error" mb={1}>
          ({startCase(env)})
        </Typography>

        <Typography variant="subtitle1" textAlign={'center'} mb={1}>
          {formatMessage(
            isProd ? messages.subtitleProd : messages.subtitleEnv,
            { env: startCase(env), acc: lowerCase(env) },
          )}
        </Typography>

        <form noValidate>
          {isProd && (
            <LoadingButton
              fullWidth
              loading={isPendingGoogleLogin}
              color="primary"
              variant="contained"
              className={classes.submit}
              sx={{ borderRadius: '8px' }}
              onClick={() => loginGoogle()}
            >
              {formatMessage(messages.signInWithGoogle)}
            </LoadingButton>
          )}
          {!isProd && (
            <Fragment>
              <TextInput
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label={formatMessage(messages.emailAddressLabel)}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                className={classes.textInput}
              />
              <TextInput
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={formatMessage(messages.passwordLabel)}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                className={classes.textInput}
              />
            </Fragment>
          )}
          {!isProd && (
            <LoadingButton
              fullWidth
              id="loginButton"
              color="secondary"
              variant="contained"
              onClick={handleLogin}
              loading={isPendingLogin}
              className={classes.submit}
              sx={{ borderRadius: '8px' }}
            >
              {formatMessage(messages.signIn)}
            </LoadingButton>
          )}
        </form>
      </Paper>
    </Container>
  );
}
