import { useQuery } from '@tanstack/react-query';

import { API } from 'src/reactQuery/Api';
import { getRequest } from 'src/utils/request';
import { toMoment } from 'src/utils/datetime';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';

import type { RequestData } from 'src/types/requestData';

const mapUserAccount = (
  userAccount: UCM.UserEvaluationRawType,
): UCM.UserEvaluationType => ({
  email: userAccount.email,
  userId: userAccount.user_id,
  userUuid: userAccount.user_uuid,
  firstName: userAccount.first_name,
  lastName: userAccount.last_name,
  birthDate: toMoment(userAccount.birth_date),
  cityCodes: userAccount.city_codes,
  localeCode: userAccount.locale_code,
  germanLevel: userAccount.german_level,
  englishLevel: userAccount.english_level,
  jobTypeCodes: userAccount.job_type_codes,
  occupationCode: userAccount.occupation_code,
  graduationYear: userAccount.graduation_year,
  preAccessStage: userAccount.pre_access_stage,
  flexibilityCode: userAccount.flexibility_code,
  availabilityCode: userAccount.availability_code,
  citizenshipCountryCode: userAccount.citizenship_country_code,
  phone: {
    number: userAccount.phone?.number ?? '',
    callingCode: userAccount.phone?.calling_code?.toString() ?? '',
  },
  cityCode: userAccount.city_code,
  prescreening: userAccount.prescreening,
  registrationStatus: userAccount.registration_status,
});

const getUserAccount = async (uuid: string) => {
  const {
    data: {
      data: { attributes },
    },
  } = await getRequest<RequestData<UCM.UserEvaluationRawType>>(
    API.GET.getUserAccount(uuid),
  );

  return mapUserAccount(attributes);
};

export const useGetUserAccount = (
  uuid: string,
  config: { enabled?: boolean } = { enabled: true },
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.USER_ACCOUNT, uuid],
    queryFn: () => getUserAccount(uuid),
    staleTime: 1000 * 60,
    ...config,
  });
};
