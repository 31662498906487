import { useRef } from 'react';
import { HttpStatusCode } from 'axios';
import { useNavigate } from 'react-router-dom';
import useMessenger from 'src/utils/hooks/useMessenger';
import { removeAccessToken } from 'src/utils/hooks/useAuthentication';

export interface ErrorType {
  message: string;
  status: HttpStatusCode;
  response: {
    status: HttpStatusCode;
    data: {
      error?: {
        details: string;
        status: number;
        title: string;
      };
      errors?: Array<{
        source: {
          pointer?: string;
        };
        detail: string;
        details: string;
      }>;
    };
  };
}

export function useMessageHandler() {
  const navigation = useNavigate();
  const messenger = useMessenger();

  const handleError = useRef((error: ErrorType) => {
    if (!error || !error.response) return;

    const { response: { data, status } } = error;

    // Unauthorized
    if (status === 401) {
      removeAccessToken();
      navigation('/');
      return;
    }
    if (status !== 422) {
      messenger.error(error.message);
      return;
    }
    if (data.error) {
      messenger.error(data.error.details);
    }
    data.errors?.forEach((item) => {
      let field =
        item.source?.pointer?.substring(
          item.source.pointer.lastIndexOf('/') + 1,
        ) || '';
      field = field ? `${field}: ` : '';
      messenger.error(`${field}${item.detail || item.details}`);
    });
  }).current;

  const handleSuccess = useRef(
    (successMessage: string, autoHideDuration?: number) => {
      messenger.success(successMessage, autoHideDuration);
    },
  ).current;

  return { handleError, handleSuccess };
}
