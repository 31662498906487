import React, { useCallback } from 'react';

import Grid from '@mui/material/Grid2';
import { IconButton, Typography } from '@mui/material';

// Import SVGs as React components
import { ReactComponent as FineIcon } from 'src/images/Fine.svg';
import { ReactComponent as SetupIcon } from 'src/images/Setup.svg';
import { ReactComponent as SearchIcon } from 'src/images/Search.svg';
import { ReactComponent as StudentIcon } from 'src/images/Student.svg';
import { ReactComponent as ProjectIcon } from 'src/images/Project.svg';
import { ReactComponent as ContractIcon } from 'src/images/Contract.svg';
import { ReactComponent as TimeTrackingIcon } from 'src/images/TimeTracking.svg';
import { ReactComponent as CancellationIcon } from 'src/images/Cancellation.svg';
import { ReactComponent as VerificationIcon } from 'src/images/Verification.svg';

import PageLayout from 'src/components/PageLayout';
import useMessenger from 'src/utils/hooks/useMessenger';

interface MenuItem {
  url: string;
  text: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export const homeIcons: Array<MenuItem> = [
  { text: 'Projects', icon: ProjectIcon, url: '/' },
  { text: 'Cancellations', icon: CancellationIcon, url: '/' },
  { text: 'Students', icon: StudentIcon, url: '/' },
  { text: 'Verification', icon: VerificationIcon, url: '/' },
  { text: 'Contracts', icon: ContractIcon, url: '/' },
  { text: 'Time Tracking', icon: TimeTrackingIcon, url: '/' },
  { text: 'Fines', icon: FineIcon, url: '/' },
  { text: 'Setup', icon: SetupIcon, url: '/' },
  { text: 'Search', icon: SearchIcon, url: '/' },
];

export default function HomePage() {
  const messenger = useMessenger();

  const handleItemClick = useCallback(
    (text: string) =>
      messenger.info(
        <div>
          Oops! The "{text}" feature is still under construction. <br />
          We're working hard to bring it to you soon. Thanks for your patience!
        </div>,
      ),
    [],
  );

  return (
    <PageLayout title="Home">
      <Grid container rowSpacing={12} pt={8} alignItems="center" alignContent="center">
        {homeIcons.map(({ text, icon: Icon }) => {
          return (
            <Grid key={text} size={{ xs: 12, sm: 6, md: 4 }}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                flexDirection="column"
              >
                <IconButton onClick={() => handleItemClick(text)}>
                  <Icon width={88} height={88} />
                </IconButton>
                <Typography variant="subtitle1">{text}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </PageLayout>
  );
}
