/*
 * Login Page Messages
 *
 * This contains all the text for the LoginPage component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'src.pages.Auth.LoginPage';

export default defineMessages({
  titleProd: {
    id: `${scope}.titleProd`,
    defaultMessage: 'Login to Backstage',
  },
  subtitleEnv: {
    id: `${scope}.subtitleEnv`,
    defaultMessage:
      'If you gained access to UCM Backstage ({env}), you can login with your {acc} account.',
  },
  subtitleProd: {
    id: `${scope}.subtitleProd`,
    defaultMessage:
      'If you gained access to UCM Backstage, you can login with your account.',
  },
  emailAddressLabel: {
    id: `${scope}.emailAddressLabel`,
    defaultMessage: 'Email Address',
  },
  passwordLabel: {
    id: `${scope}.passwordLabel`,
    defaultMessage: 'Password',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign In',
  },
  signInWithGoogle: {
    id: `${scope}.signInWithGoogle`,
    defaultMessage: 'Login with Google',
  },
});
