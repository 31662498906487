import { useCallback, useMemo, useRef, useState } from 'react';

import { useIntl } from 'react-intl';

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { isProd } from 'src/config';
import SearchFilterBar, {
  resolveQuickSearch,
  type QuickFiltersType,
} from 'src/components/SearchFilterBar';
import DataTable from 'src/components/DataTable';
import PageLayout from 'src/components/PageLayout';
import { isOlderThanThreeDays } from 'src/utils/datetime';

// SVGs
import { ReactComponent as PlayIcon } from 'src/images/Play.svg';
import { ReactComponent as PencilIcon } from 'src/images/Pencil.svg';
import { ReactComponent as DisablePlay } from 'src/images/DisablePlay.svg';
import { ReactComponent as PersonEditIcon } from 'src/images/PersonEdit.svg';
import { ReactComponent as TransitionPushIcon } from 'src/images/TransitionPush.svg';
import { ReactComponent as AssignmentTurnedInIcon } from 'src/images/AssignmentTurnedIn.svg';

import EvaluationFormModal from './components/EvaluationFormModal';
import PreRegisterFormModal from './components/PreRegisterFormModal';
import { useUpdatePrescreening } from './api/mutations/useUpdatePrescreening';

import messages from './messages';
import { useGetUserAccounts } from './api';
import { useCreatePrescreening } from './api';
import { useCreateUserAccount, useUpdateUserAccount } from './api';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { useUpdateGrantStudentAccess } from './api/mutations/useUpdateGrantStudentAccess';

const navTabs: Array<{ value: UCM.CandidateStatus; label: string }> = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'pre_registered',
    label: 'Pre-registered',
  },
  {
    value: 'app_registered',
    label: 'App-registered',
  },
  {
    value: 'prescreening_missing',
    label: 'Interview missing',
  },
  {
    value: 'awaiting_evaluation',
    label: 'To evaluate',
  },
  {
    value: 'queued',
    label: 'Queued',
  },
  // {
  //   value: 'access_paused',
  //   label: 'Access paused',
  // },
];

export default function GatekeeperPage() {
  const { formatMessage } = useIntl();

  const { mutate: createUserAccount } = useCreateUserAccount();
  const { mutate: updateUserAccount } = useUpdateUserAccount();
  const { mutate: createPrescreening } = useCreatePrescreening();
  const { mutate: updatePrescreening } = useUpdatePrescreening();
  const { mutate: updateStudentAccess } = useUpdateGrantStudentAccess();

  const [activeTab, setActiveTab] = useState<UCM.CandidateStatus>(
    navTabs[0].value,
  );
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [params, setParams] = useState<Record<string, any>>({ page: 1 });

  const { data: userAccounts, isFetching: isFetchingUserAccounts } =
    useGetUserAccounts(activeTab, params, resolveQuickSearch(filters));

  const quickFilters: Array<QuickFiltersType> = useRef([
    {
      autoFocus: true,
      id: 'quick_search',
      type: 'text' as QuickFiltersType['type'],
      placeholder: formatMessage(messages.searchInputPlaceholder),
    },
  ]).current;

  const firstFixedColumns = useMemo(() => {
    return [
      {
        id: 'email',
        label: formatMessage(messages.emailColumnLabel),
      },
      {
        id: 'phoneNumber',
        label: formatMessage(messages.phoneColumnLabel),
        render: (phoneNumber: string | null) => (
          <span>{phoneNumber || '-'}</span>
        ),
      },
      {
        id: 'firstName',
        label: formatMessage(messages.firstNameColumnLabel),
        render: (firstName: string) => <span>{firstName}</span>,
      },
      {
        id: 'lastName',
        label: formatMessage(messages.lastNameColumnLabel),
        render: (lastName: string) => <span>{lastName}</span>,
      },
    ];
  }, []);

  const statusColumn = useMemo(() => {
    return {
      id: 'status',
      label: formatMessage(messages.statusColumnLabel),
      render: (_: Object, { prescreening }: UCM.UserAccountType) => (
        <span>{prescreening?.status || '-'}</span>
      ),
    };
  }, []);

  const basicProfileCompletedColumn = useMemo(() => {
    return {
      id: 'basicProfileCompletedAt',
      label: formatMessage(messages.baseProfileSinceColumnLabel),
      render: (completedAt: UCM.UserAccountType['basicProfileCompletedAt']) => {
        if (!completedAt) return <span>-</span>;
        const isOlder = isOlderThanThreeDays(completedAt);
        return (
          <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
            {completedAt}
          </span>
        );
      },
    };
  }, []);

  const prescreeningEvaluationColumn = useMemo(() => {
    return {
      id: 'prescreening',
      label: formatMessage(messages.evaluatedSinceColumnLabel),
      render: (prescreening: UCM.UserAccountType['prescreening']) => {
        if (!prescreening?.createdAt) return <span>-</span>;
        const isOlder = isOlderThanThreeDays(prescreening.createdAt);
        return (
          <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
            {prescreening.createdAt}
          </span>
        );
      },
    };
  }, []);

  const interviewCompletedColumn = useMemo(() => {
    return {
      id: 'interviewCompletedAt',
      label: formatMessage(messages.interviewCompletedColumnLabel),
      render: (
        interviewCompletedAt: UCM.UserAccountType['interviewCompletedAt'],
      ) => {
        if (!interviewCompletedAt) return <span>-</span>;
        const isOlder = isOlderThanThreeDays(interviewCompletedAt);
        return (
          <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
            {interviewCompletedAt}
          </span>
        );
      },
    };
  }, []);

  const userPotentialColumn = useMemo(() => {
    return {
      id: 'userPotential',
      label: formatMessage(messages.userProfileColumnLabel),
      render: (user_potential: number) => <span>{user_potential || '-'}</span>,
    };
  }, []);

  const columns: Record<
    UCM.CandidateStatus,
    Array<{ id: keyof UCM.UserAccountType | string }>
  > = useMemo(
    () => ({
      all: [
        ...firstFixedColumns,
        {
          id: 'stage',
          label: formatMessage(messages.stageColumnLabel),
          render: (stage: string | null) => <span>{stage}</span>,
        },
        {
          id: 'stageTime',
          label: formatMessage(messages.stageTimeColumnLabel),
          render: (stageTime: string | null) => {
            if (!stageTime) return <span>-</span>;
            const isOlder = isOlderThanThreeDays(stageTime);
            return (
              <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
                {stageTime}
              </span>
            );
          },
        },
      ],
      pre_registered: [
        ...firstFixedColumns,
        {
          id: 'createdAt',
          label: formatMessage(messages.leadSinceColumnLabel),
          render: (createdAt: string) => {
            const isOlder = isOlderThanThreeDays(createdAt);
            return (
              <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
                {createdAt}
              </span>
            );
          },
        },
        // TODO - BE not supported for this yet
        // {
        //   id: 'campaign',
        //   label: formatMessage(messages.campaignColumnLabel),
        //   render: () => '-',
        // },
        {
          id: 'prescreening',
          label: formatMessage(messages.statusColumnLabel),
          render: (prescreening: UCM.UserAccountType['prescreening']) => (
            <span>{prescreening?.status || '-'}</span>
          ),
        },
        {
          id: 'action',
          align: 'center',
          label: '',
          maxWidth: '60px',
          render: (_: Object, item: UCM.UserAccountType) => {
            return (
              <Grid container>
                <Grid size={{ lg: 6, md: 12, sm: 12 }}>
                  <PreRegisterFormModal
                    isEdit
                    userUuid={item.userUuid}
                    onUpdate={updateUserAccount}
                    triggerButton={(handleOpen) => (
                      <Tooltip title={formatMessage(messages.editPreregHint)}>
                        <IconButton onClick={handleOpen}>
                          <PersonEditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  />
                </Grid>
                <Grid size={{ lg: 6, md: 12, sm: 12 }}>
                  <EvaluationFormModal
                    userEmail={item.email}
                    userUuid={item.userUuid}
                    onUpdate={updatePrescreening}
                    onCreate={createPrescreening}
                    isEdit={item.prescreening != null}
                    uuid={item.prescreening?.uuid ?? ''}
                    triggerButton={(handleOpen) => {
                      const isEditMode = item.prescreening != null;
                      const iconHint = isEditMode
                        ? messages.editPrescreenHint
                        : messages.createPrescreenHint;

                      return (
                        <Tooltip title={formatMessage(iconHint)}>
                          <IconButton onClick={handleOpen}>
                            {isEditMode ? (
                              <PencilIcon />
                            ) : (
                              <AssignmentTurnedInIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            );
          },
        },
      ],
      app_registered: [
        ...firstFixedColumns,
        {
          id: 'createdAt',
          label: formatMessage(messages.accountSinceColumnLabel),
          render: (createdAt: string) => {
            const isOlder = isOlderThanThreeDays(createdAt);
            return (
              <span style={{ color: isOlder ? 'orangeRed' : 'inherit' }}>
                {createdAt}
              </span>
            );
          },
        },
        prescreeningEvaluationColumn,
        statusColumn,
        {
          id: 'action',
          label: '',
          align: 'center',
          maxWidth: '60px',
          render: (_: Object, item: UCM.UserAccountType) => {
            return (
              <Grid container>
                <Grid size={6}>
                  <EvaluationFormModal
                    userEmail={item.email}
                    userUuid={item.userUuid}
                    onUpdate={updatePrescreening}
                    onCreate={createPrescreening}
                    isEdit={item.prescreening != null}
                    uuid={item.prescreening?.uuid ?? ''}
                    triggerButton={(handleOpen) => {
                      const isEditMode = item.prescreening != null;
                      const iconHint = isEditMode
                        ? messages.editPrescreenHint
                        : messages.createPrescreenHint;

                      return (
                        <Tooltip title={formatMessage(iconHint)}>
                          <IconButton onClick={handleOpen}>
                            {isEditMode ? (
                              <PencilIcon />
                            ) : (
                              <AssignmentTurnedInIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      );
                    }}
                  />
                </Grid>
                <Grid size={6}>{forceAccessComponent(item.userUuid)}</Grid>
              </Grid>
            );
          },
        },
      ],
      prescreening_missing: [
        ...firstFixedColumns,
        statusColumn,
        basicProfileCompletedColumn,
        userPotentialColumn,
        {
          id: 'action',
          align: 'center',
          maxWidth: '60px',
          label: formatMessage(messages.evaluateColumnLabel),
          render: (_: Object, item: UCM.UserAccountType) => {
            return (
              <Grid container>
                <Grid size={6}>
                  <EvaluationFormModal
                    userEmail={item.email}
                    userUuid={item.userUuid}
                    onUpdate={updatePrescreening}
                    onCreate={createPrescreening}
                    isEdit={item.prescreening != null}
                    uuid={item.prescreening?.uuid ?? ''}
                    triggerButton={(handleOpen) => {
                      const isEditMode = item.prescreening != null;
                      const iconHint = isEditMode
                        ? messages.editPrescreenHint
                        : messages.createPrescreenHint;

                      return (
                        <Tooltip title={formatMessage(iconHint)}>
                          <IconButton onClick={handleOpen}>
                            {isEditMode ? (
                              <PencilIcon />
                            ) : (
                              <AssignmentTurnedInIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      );
                    }}
                  />
                </Grid>

                <Grid size={6}>{forceAccessComponent(item.userUuid)}</Grid>
              </Grid>
            );
          },
        },
      ],
      awaiting_evaluation: [
        ...firstFixedColumns,
        interviewCompletedColumn,
        basicProfileCompletedColumn,
        userPotentialColumn,
        {
          id: 'video',
          align: 'center',
          label: formatMessage(messages.videoColumnLabel),
          render: (_: Object, { prescreening }: UCM.UserAccountType) => {
            if (!prescreening?.interviewManagerUrl || !prescreening?.showUrl)
              return (
                <Tooltip title={formatMessage(messages.videoUrlHint)}>
                  <DisablePlay />
                </Tooltip>
              );

            return (
              <Tooltip title={formatMessage(messages.videoUrlHint)}>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={prescreening.interviewManagerUrl}
                >
                  <PlayIcon />
                </Link>
              </Tooltip>
            );
          },
        },
        {
          id: 'action',
          align: 'center',
          maxWidth: '60px',
          label: formatMessage(messages.evaluateColumnLabel),
          render: (_: Object, item: UCM.UserAccountType) => {
            return (
              <Grid container>
                <Grid size={6}>
                  <EvaluationFormModal
                    userEmail={item.email}
                    userUuid={item.userUuid}
                    onUpdate={updatePrescreening}
                    onCreate={createPrescreening}
                    isEdit={item.prescreening != null}
                    uuid={item.prescreening?.uuid ?? ''}
                    triggerButton={(handleOpen) => {
                      const isEditMode = item.prescreening != null;
                      const iconHint = isEditMode
                        ? messages.editPrescreenHint
                        : messages.createPrescreenHint;

                      return (
                        <Tooltip title={formatMessage(iconHint)}>
                          <IconButton onClick={handleOpen}>
                            {isEditMode ? (
                              <PencilIcon />
                            ) : (
                              <AssignmentTurnedInIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      );
                    }}
                  />
                </Grid>
                <Grid size={6}>{forceAccessComponent(item.userUuid)}</Grid>
              </Grid>
            );
          },
        },
      ],
      queued: [
        ...firstFixedColumns,
        prescreeningEvaluationColumn,
        {
          id: 'evaluatedBy',
          label: formatMessage(messages.evaluatedByColumnLabel),
          render: (_: Object, { prescreening }: UCM.UserAccountType) => (
            <span>{prescreening?.verifiedBy || '-'}</span>
          ),
        },
        {
          id: 'accessStatus',
          label: formatMessage(messages.reasonColumnLabel),
          render: (status: string | null) => <span>{status || '-'}</span>,
        },
        {
          id: 'action',
          label: '',
          maxWidth: '50px',
          render: (_: Object, item: UCM.UserAccountType) => {
            return (
              <Grid container>
                <Grid size={6}>
                  <EvaluationFormModal
                    userEmail={item.email}
                    userUuid={item.userUuid}
                    onUpdate={updatePrescreening}
                    onCreate={createPrescreening}
                    isEdit={item.prescreening != null}
                    uuid={item.prescreening?.uuid ?? ''}
                    triggerButton={(handleOpen) => {
                      const isEditMode = item.prescreening != null;
                      const iconHint = isEditMode
                        ? messages.editPrescreenHint
                        : messages.createPrescreenHint;

                      return (
                        <Tooltip title={formatMessage(iconHint)}>
                          <IconButton onClick={handleOpen}>
                            {isEditMode ? (
                              <PencilIcon />
                            ) : (
                              <AssignmentTurnedInIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      );
                    }}
                  />
                </Grid>
                <Grid size={6}>{forceAccessComponent(item.userUuid)}</Grid>
              </Grid>
            );
          },
        },
      ],
      // access_paused: [
      //   {
      //     id: 'email',
      //     label: formatMessage(messages.emailColumnLabel),
      //   },
      // {
      //   id: 'firstName',
      //   label: formatMessage(messages.firstNameColumnLabel),
      //   render: (firstName: string) => {
      //     return <span>{firstName}</span>;
      //   },
      // },
      // {
      //   id: 'lastName',
      //   label: formatMessage(messages.lastNameColumnLabel),
      //   render: (lastName: string) => {
      //     return <span>{lastName}</span>;
      //   },
      // },
      //   {
      //     id: 'phoneNumber',
      //     label: formatMessage(messages.phoneColumnLabel),
      //     render: (phoneNumber: string | null) => {
      //       if (phoneNumber == null) return '-';
      //       return <span>{phoneNumber}</span>;
      //     },
      //   },
      //   {
      //     id: 'accessPausedAt',
      //     label: formatMessage(messages.accessPausedColumnLabel),
      //     render: (pausedAt: string | null) => {
      //       if (pausedAt == null) return '-';
      //       return <span>{pausedAt}</span>;
      //     },
      //   },
      //   {
      //     id: 'accessPausedReasons',
      //     label: formatMessage(messages.accessReasonsLabel),
      //     render: (reasons: UCM.UserAccountType['accessPausedReasons']) => {
      //       if (reasons == null) return '-';

      //       return (
      //         <ul>
      //           {reasons.map(({ code, title }) => (
      //             <li key={code}>{title}</li>
      //           ))}
      //         </ul>
      //       );
      //     },
      //   },
      //   {
      //     id: 'action',
      //     label: '',
      //     maxWidth: '60px',
      //     render: (_: Object, item: UCM.UserAccountType) => {
      //       return (
      //         <Grid container>
      //           <Grid size={{ lg: 6, md: 12, sm: 12 }}>
      //             <EvaluationFormModal
      //               userEmail={item.email}
      //               userUuid={item.userUuid}
      //               onUpdate={updatePrescreening}
      //               onCreate={createPrescreening}
      //               isEdit={item.prescreening != null}
      //               uuid={item.prescreening?.uuid ?? ''}
      //               triggerButton={(handleOpen) => {
      //                 const isEditMode = item.prescreening != null;
      //                 const iconHint = isEditMode
      //                   ? messages.editPrescreenHint
      //                   : messages.createPrescreenHint;

      //                 return (
      //                   <Tooltip title={formatMessage(iconHint)}>
      //                     <IconButton onClick={handleOpen}>
      //                       {isEditMode ? (
      //                         <PencilIcon />
      //                       ) : (
      //                         <AssignmentTurnedInIcon />
      //                       )}
      //                     </IconButton>
      //                   </Tooltip>
      //                 );
      //               }}
      //             />
      //           </Grid>

      //           <Grid size={{ lg: 6, md: 12, sm: 12 }}>
      //             {forceAccessComponent(item.userUuid)}
      //           </Grid>
      //         </Grid>
      //       );
      //     },
      //   },
      // ],
    }),
    [activeTab],
  );

  const forceAccessComponent = useCallback((candidateUuid: string) => {
    if (isProd) {
      return null;
    }

    return (
      <ConfirmationDialog
        maxWidth="sm"
        onConfirm={() => updateStudentAccess(candidateUuid)}
        message={formatMessage(messages.accessGrantedConfirmMessage)}
      >
        {(handleOpen) => (
          <Tooltip title={formatMessage(messages.forceAccessHint)}>
            <IconButton onClick={handleOpen}>
              <TransitionPushIcon />
            </IconButton>
          </Tooltip>
        )}
      </ConfirmationDialog>
    );
  }, []);

  const handleSearch = useCallback((filters: Record<string, any>) => {
    setFilters(filters);
  }, []);

  const handlePagination = useCallback((event: unknown, page: number) => {
    setParams({ page: page + 1 });
  }, []);

  return (
    <PageLayout
      title="Gatekeeper"
      tabs={navTabs}
      activeTab={activeTab}
      setActiveTab={(tab: string) => {
        setActiveTab(tab as UCM.CandidateStatus);
        setParams({ page: 1 });
      }}
    >
      <Grid container spacing={2} width="100%">
        <Grid size={3}>
          <SearchFilterBar fields={quickFilters} onSearch={handleSearch} />
        </Grid>

        <Grid
          size={9}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid size={6}>
              {activeTab === 'pre_registered' ? (
                <PreRegisterFormModal
                  isEdit={false}
                  onCreate={createUserAccount}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <DataTable
            columns={columns[activeTab]}
            rows={userAccounts?.data ?? []}
            pagination={userAccounts?.meta}
            onPaginate={handlePagination}
            isLoading={isFetchingUserAccounts}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}
