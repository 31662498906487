import axios, {
  AxiosResponse,
  HttpStatusCode,
  AxiosRequestConfig,
} from 'axios';
import config, { locale } from 'src/config';
import { getAccessToken, removeAccessToken } from './hooks/useAuthentication';

const { endpointHost } = config;
const noBodyCodes = [204, 205, 401, 404, 500];

axios.defaults.baseURL = endpointHost;
axios.defaults.headers.common.Accept = 'application/vnd.falcon.v3+json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept-Language'] = 'en';

axios.interceptors.response.use(
  (response) => response, // Return response if no error
  (error) => {
    // Check if it's a 401 error
    if (error.response && error.response.status === 401) {
      // Remove access token and log the user out
      removeAccessToken();
      window.location.href = '/login';
    }

    // If it's not a 401 error, reject the promise with the error
    return Promise.reject(error);
  },
);

export const hasNoBody = (response: { status: HttpStatusCode }) => {
  return noBodyCodes.includes(response.status);
};

export const setupAxios = (accessToken?: string) => {
  const token = accessToken ?? getAccessToken();

  if (token != null)
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  else if (token === null) delete axios.defaults.headers.common.Authorization;
  axios.defaults.headers.common['Content-Language'] = locale || 'en';
};

const http = () => axios;

export const getRequest = async <T = any>(
  url: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  try {
    const response = await axios.get<T>(url, options);
    return response;
  } catch (error) {
    throw error;
  } finally {
  }
};

export const postRequest = async <T = any>(
  url: string,
  data?: any,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  try {
    const response = await axios.post<T>(url, data, options);
    return response;
  } catch (error) {
    throw error;
  } finally {
  }
};

export const deleteRequest = async <T = any>(
  url: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  try {
    const response = await axios.delete<T>(url, options);
    return response;
  } catch (error) {
    throw error;
  } finally {
  }
};

export const putRequest = async <T = any>(
  url: string,
  data?: any,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  try {
    const response = await axios.put(url, data, options);
    return response;
  } catch (error) {
    throw error;
  }
};

export const patchRequest = async <T = any>(
  url: string,
  data?: any,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  try {
    const response = await axios.patch(url, data, options);
    return response;
  } catch (error) {
    throw error;
  }
};

export const paginationMapper = (meta: UCM.MetaRawType): UCM.MetaType => ({
  totalData: meta.total_data,
  totalPages: meta.total_pages,
  currentPage: meta.current_page,
});

/**
 * Convert a JSON object, returning a query string
 */
export function serialize(params: Record<string, any>, prefix = ''): string {
  if (!params) return '';

  const query = Object.keys(params).reduce<string[]>((accum, item) => {
    let key = item;
    const value = params[key];

    if (Array.isArray(params)) {
      key = `${prefix}[]`;
    } else if (typeof params === 'object') {
      key = prefix ? `${prefix}[${key}]` : key;
    }

    if (value === undefined) {
      return accum;
    }

    if (typeof value === 'object' && value !== null) {
      const moreParams = serialize(value, key);
      if (moreParams) accum.push(moreParams);
    } else {
      accum.push(`${key}=${encodeURIComponent(value)}`);
    }

    return accum;
  }, []);

  return query.join('&');
}

export default http;
