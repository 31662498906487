import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { colors } from 'src/utils/customColors';
import { ReactComponent as CloseIcon } from 'src/images/Close.svg';

import messages from './messages';
import { Styled } from './index.styled';

import type { QueryObserverResult } from '@tanstack/react-query';
import ConfirmationDialog from '../ConfirmationDialog';

interface ConfirmModalProps {
  title: string;
  disabled?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  submitTextButton?: string;
  confirmationMessage?: string;
  isRequiredConfirmationPopup?: boolean;
  confirmationDialogMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onOpen?: () => Promise<QueryObserverResult<any, any>> | (() => void);
  onConfirm: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  triggerButton: (
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
  ) => React.ReactNode;
}

export default function ConfirmModal({
  title,
  onOpen,
  onClose,
  children,
  disabled,
  onConfirm,
  triggerButton,
  submitTextButton,
  confirmationMessage,
  confirmationDialogMaxWidth = 'sm',
  isRequiredConfirmationPopup = false,
}: ConfirmModalProps) {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (mountedRef.current) setOpen(true);
    if (onOpen != null) onOpen();
  }, []);

  const handleClose = useCallback(() => {
    if (mountedRef.current) setOpen(false);
    if (typeof onClose === 'function') onClose();
  }, [onClose]);

  const handleConfirm = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      let close: boolean | void = true;

      try {
        close = await onConfirm(event);
      } catch (error) {
        console.error(error);
        close = false;
      }

      if (close !== false) handleClose();
    },
    [onConfirm, handleClose],
  );

  const renderButton = useCallback(
    (handleClick: (event: React.MouseEvent<HTMLElement>) => void) => (
      <Button
        onClick={handleClick}
        sx={{
          fontWeight: 600,
          boxShadow: disabled ? 0 : undefined,
          pointerEvents: disabled ? 'none' : undefined,
          color: disabled ? colors.brown : colors.white,
          backgroundColor: disabled ? colors.brown : colors.darkBlue,
        }}
      >
        {submitTextButton || formatMessage(messages.submitButtonLabel)}
      </Button>
    ),
    [disabled, formatMessage, submitTextButton],
  );

  return (
    <div>
      {triggerButton(handleOpen)}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Styled.ModalContainer>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography variant="h3" component="h3" fontSize={20}>
              {title}
            </Typography>

            <Styled.CloseContainer onClick={handleClose}>
              <CloseIcon />
            </Styled.CloseContainer>
          </Stack>

          <Styled.Separator />

          {children}

          <Stack
            mt={3}
            gap={2}
            flexDirection={'row'}
            justifyContent={'flex-end'}
          >
            {isRequiredConfirmationPopup && confirmationMessage ? (
              <ConfirmationDialog
                onConfirm={handleConfirm}
                message={confirmationMessage}
                maxWidth={confirmationDialogMaxWidth}
              >
                {(handleDialogOpen) => renderButton(handleDialogOpen)}
              </ConfirmationDialog>
            ) : (
              renderButton(handleConfirm)
            )}
          </Stack>
        </Styled.ModalContainer>
      </Modal>
    </div>
  );
}
