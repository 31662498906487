import { QueryClient } from '@tanstack/react-query';

export default require(`./${process.env.REACT_APP_SERVER_ENV || 'staging'}`)
  .default;

export const env = process.env.REACT_APP_SERVER_ENV;

export const isDev =
  process.env.REACT_APP_SERVER_ENV &&
  process.env.REACT_APP_SERVER_ENV.includes('development');

export const isProd = process.env.REACT_APP_SERVER_ENV === 'production';

export const locale = 'de';
export const currency = 'EUR';

export const queryClient = new QueryClient();
